import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {
  ano: number;

  constructor() { }

  ngOnInit() {
    let date: Date = new Date();
    this.ano = date.getFullYear();
  }

}
