import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { OneServiceService } from '../../Services/one-service.service';
import { UserModule } from '../Models/user.module';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    //email: string;
    recuerdame: boolean = false;
    public isError = false;
    public usserLogged: UserModule;
  
    //name = 'Angular';
  
    //@ViewChild(NavbarComponent, {static: false}) hello: NavbarComponent;
  
    usuario = {
      correo: '',
      password: '',
    }
  
    constructor(private router: Router, private Servicio: OneServiceService) {
    }
  
    ngOnInit() {
      this.usuario.correo = localStorage.getItem('email') || ' ';
      if(this.usuario.correo.length > 1){
        this.recuerdame = true;
      }
    }
  
    Login(forma: NgForm) {
      //console.log(forma.value);
  
      if( forma.invalid ){
          Object.values( forma.controls ).forEach( control => {
            control.markAsTouched();
          });
          return;
      }else{
        this.Servicio.Login(forma.value).subscribe(
          resp => {
            //console.log(resp);
            if(resp['resultado'] == 'OK') {
            let u: UserModule = { token: resp['token'] };
            this.Servicio.setUserLoggedIn(u);
            this.navigate();
            }
        });
        //console.log("Entro Validado"); */
      }
    }
  
    navigate() {
      this.router.navigate(['panel']);
    }
  
  }
  