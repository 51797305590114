import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OneServiceService } from '../../Services/one-service.service';
import { UserModule } from '../Models/user.module';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent implements OnInit {

  public usserLogged: UserModule;

  constructor(private Service: OneServiceService, private router: Router) { }

  ngOnInit() {
    if (this.Service.logIn === false)   {  ///para el panel
      this.router.navigate(['login']);    //console.log(this.Service.logIn);
    }
  }

}
