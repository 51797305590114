import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FormControl } from '@angular/forms';
import { UserModule } from '../components/Models/user.module';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OneServiceService {

  private isUserLoggedIn;
  public usserLogged: UserModule;
  baseUrl = environment.baseUrl;
  token: string;

  constructor(private router: Router, private http: HttpClient) { this.isUserLoggedIn = false; this.cargarStorage(); }

  getCards() { return this.http.get(`${ this.baseUrl }/getCards.php`); }

  addUsuario(usuario: any) {
    return this.http.post(`${this.baseUrl}/postU.php`, usuario);
  }

  Login(usuario: any) {
      //console.log(usuario.recuerdame);
      if(usuario.recuerdame){
        localStorage.setItem('email',usuario.correo);
      }else{
        localStorage.removeItem('email');
      }
    return this.http.post(`${this.baseUrl}/Login.php`, usuario);
  }

  Revisa(usuario: any) {
    //console.log(this.http.post(`${this.baseUrl}/confirmaEmail.php`, usuario));
    return this.http.post(`${this.baseUrl}/confirmaEmail.php`, usuario);
  }

  setUserLoggedIn(user: UserModule) {
    this.isUserLoggedIn = true;
    this.usserLogged = user;
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  getUserLoggedIn() {
  	return JSON.parse(localStorage.getItem('currentUser'));
  }

  cargarStorage(){
    //console.log( this.getUserLoggedIn() );
    if( this.getUserLoggedIn() ){
      this.token = this.getUserLoggedIn();
    }else{
      this.token = '';
    }
  }

  estaLogueado(){
      this.token = this.getUserLoggedIn();
      //console.log(this.token['token']);
      if(this.token){
        this.token = this.token['token'];
        if(this.token.length > 100 ) {
          return true;
        } else {
          return false;
        }
      }else{
        return false;
      }
  }

  ClearlocalStorage(){
    localStorage.removeItem('currentUser');
    //localStorage.clear();
  }

  logout () {
     localStorage.removeItem('token');
  }

  public get logIn(): boolean {
    return (localStorage.getItem('currentUser') !== null);
  } //logIn: devuelve una propiedad booleana que determina si un usuario está autenticado

  /*validateEmailNotTaken(ctrl: AbstractControl) {
    return (
      this
       .checkForExists(ctrl.value)
       .pipe(map(taken => taken ? { taken: true } : null))
    )
  }*/

}
