import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { OneServiceService } from '../../Services/one-service.service';
import { UserModule } from '../Models/user.module';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  public usserLogged: UserModule;

  constructor(private router: Router, private Service: OneServiceService) { }

  ngOnInit() {
    this.RevisarToken();
  }

  RevisarToken() {
    this.usserLogged = this.Service.getUserLoggedIn();
    //console.log(this.Service.getUserLoggedIn());
  }

  Salir() {
    this.Service.ClearlocalStorage();
    this.RevisarToken();
    this.router.navigate(['login']);
    //this.router.navigateByUrl('#/panel');
    //window.location.href = '#login';
  }

}
