import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './components/inicio/inicio.component';
import { LoginComponent } from './components/login/login.component';
import { PanelComponent } from './components/panel/panel.component';
import { TableroComponent } from './PanelPages/tablero/tablero.component';
import { ConfigComponent } from './PanelPages/config/config.component';

const routes: Routes = [
      {path: '', component: InicioComponent },
      {
          path: 'panel',
          component: PanelComponent,
          //canActivate: [LoginGuardGuard],
          children: [
            {path: 'tablero', component: TableroComponent },
            {path: 'config', component: ConfigComponent },
            {path: '', redirectTo: 'tablero', pathMatch: 'full' },
          ]
      },
      //{path: '', component: InicioComponent },
      {path: 'login', component: LoginComponent },
      //{path: 'register', component: RegisterComponent },
      {path: 'inicio', component: InicioComponent },
      //{path: 'bolsadetrabajo', component: BolsadetrabajoComponent },
      {path: '**', component: InicioComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
